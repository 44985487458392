import { Controller } from '@hotwired/stimulus'
/**
 * This controller should be attached to an input element. It adds `https://` prefix
 * to the input's output when submitted.
 *
 * If you also want to validate your input URL value, add the following pattern to your input html element:
 * pattern="^(https?:\/\/)?(www\.)?[^\s\/.]+[^\s]*\.[^\s\/.]{2,}([^\s]*)?(\/[^\s]*)?(\?[^\s]*)?(#[^\s]*)?$"
 */

export default class extends Controller {
  connect() {
    this.element.closest('form')?.addEventListener('submit', this.handleSubmit)
    this.element.addEventListener('blur', this.removeHttpPrefix)
    this.removeHttpPrefix()
  }

  removeHttpPrefix = () => {
    this.element.value = this.element.value.replace(/^https?:\/\//, '')
  }

  handleSubmit = () => {
    let { value } = this.element

    // Edit only if value doesn't have protocol already 'http://', 'https://', '/', or '//'
    // '/' means it's a relative URL
    if (!/^(\/|https?:\/\/|\/\/)/.test(value)) {
      value = `https://${value}`
    }

    this.element.querySelector('input').value = value
  }
}
